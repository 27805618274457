<template>
  <div>
    <el-dialog
      title="设置员工酒店数量上限"
      :visible.sync="dialogFormVisible"
      width="500px"
    >
      <div>
        <div style="margin-bottom: 30px">
          为{{ userInfo.nickName }}设置酒店数量上限（0代表无限制）
        </div>
        <el-input size="small" v-model="userInfo.hotelNum"></el-input>
        <div class="diafooterbtn">
          <el-button
            @click="cleanFn"
            style="width: 84px; margin-right: 10%"
            size="small"
            type="primary"
            plain
            >取消</el-button
          >
          <el-button
            style="width: 84px"
            @click="handEditData"
            size="small"
            type="primary"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getHotelNum_api} from '../../../apis/acc'
export default {
  data() {
    return {
      userInfo: "",
      dialogFormVisible: false,
    };
  },
  methods: {
    open(row) {
      this.userInfo = row;
      this.dialogFormVisible = true;
    },
    cleanFn() {
      this.dialogFormVisible = false;
    },
    async handEditData() {
      let { code, data } = await getHotelNum_api({
        id: this.userInfo.id,
        hotelNum: this.userInfo.hotelNum,
      });
      if(code==0){
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        this.$parent.getPerson()
      }
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>