<template>
  <div ref="reviserole">
    <el-dialog
      title="角色修改"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="角色名称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色KEY" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.key"
            autocomplete="off"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select v-model="form.status" placeholder="" size="small">
            <el-option label="在线" value="在线"></el-option>
            <el-option label="禁用" value="禁用"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.description"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getAccount: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        key: "",
        status: "",
        description: "",
      },
      formLabelWidth: "80px",
      id: "",
    };
  },
  created() {},
  methods: {
    open(row) {
   //   console.log(row,'点击当前角色传递过来的整条数据');
      this.form.key=row.roleKey;
      this.form.name=row.name;
      this.form.status=row.status=='1'?'在线':row.status=='0'?'禁用':'';
      this.form.description=row.description;
      this.dialogFormVisible = true;
      this.id = row.id;
    },
    //确认保存
    onSubmit() {
      this.reviseRole();
      this.dialogFormVisible = false;
      if (this.getAccount()) {
        this.getAccount();
      }
      this.form = {};
    },
    close() {
      this.dialogFormVisible = false;
    },
    //修改角色
    reviseRole() {
      this.$axios({
        method: "post",
        url: "/otaHotel/role/creat",
        headers: {
          "Content-Type": "application/json",
        },
        // dataType: "json",
        data: {
          name: this.form.name,
          roleKey: this.form.key,
          status: this.form.status == "禁用" ? "0" : "1",
          description: this.form.description,
          id: this.id,
        },
      })
        .then((res) => {
       //   console.log(res,'修改数据请求的响应数据');
          if(res.data.code==0){
            this.$message({
              message: "恭喜您已成功修改账号数据!",
              type: "success",
            });
            this.getAccount(); // 重绘
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input--small {
  width: 292.6px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>