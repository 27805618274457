<template>
  <div ref="addaccount">
    <el-dialog
      title="角色添加"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="角色名称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色KEY" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.key"
            autocomplete="off"
            placeholder="请输入角色KEY"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select
            v-model="form.status"
            placeholder="请选择角色状态"
            size="small"
          >
            <el-option label="在线" value="在线"></el-option>
            <el-option label="禁用" value="禁用"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.description"
            autocomplete="off"
            placeholder="请输入角色描述"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getAccount: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        key: "",
        status: "",
        description: "",
      },
      formLabelWidth: "80px",
    };
  },
  methods: {
    open() {
      this.dialogFormVisible = true;
    },
    //确认保存
    onSubmit() {
      this.addRole();
      this.dialogFormVisible = false;
      this.getAccount();
      this.form = {};
    },
    close() {
      this.dialogFormVisible = false;
    },
    //添加角色
    addRole() {
      this.$axios({
        method: "post",
        url: "/otaHotel/role/creat",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          name: this.form.name,
          roleKey: this.form.key,
          status: this.form.status == "禁用" ? "0" : "1",
          description: this.form.description,
        },
      })
        .then((res) => {
        //  console.log(res, "添加成功的响应数据");
          if (res.data.code == 0 && res.data.data == "新增成功") {
            this.$message({
              type: "success",
              message: "恭喜您成功添加角色!",
            });
            this.getAccount();
          } else {
            this.$message({
              type: "info",
              message: res.data.data,
            });
          }
        })
        .catch((error) => {
          console.log(error, "添加失败的响应数据");
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input--small {
  width: 292.6px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 80%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>