<template>
  <div class="roleManagement" ref="roleManagement">
    <!-- 角色查询/新增 -->
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="角色名称">
        <el-input size="small" v-model="formInline.user" clearable placeholder="请输入要查询的角色名称"></el-input>
      </el-form-item>
      <el-form-item label="角色KEY">
        <el-input size="small"
          v-model="formInline.region"
          clearable
          placeholder="请输入要查询的角色KEY"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="onSubmit">查询</el-button>
        <el-button size="mini" v-if="role_account_role_add" type="success" @click="add">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 角色表格 -->
    <div class="roleTable">
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed prop="name" label="角色名称" align="center">
        </el-table-column>
        <el-table-column prop="roleKey" label="角色KEY" align="center">
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium" v-if="scope.row.status == 1" type="success"
                >在线</el-tag
              >
              <el-tag size="medium" v-if="scope.row.status == 0" type="danger"
                >禁用</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center">
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="280px">
          <template slot-scope="scope">
            <el-button type="primary" v-if="role_account_role_edit" @click="revise(scope.row)" size="mini"
              >修改</el-button
            >
            <el-button type="danger" v-if="role_account_role_del" @click="del(scope.row)" size="mini"
              >删除</el-button
            >
            <el-button type="warning" v-if="role_account_role_allot" size="mini" @click="AllocateSource(scope.row)">分配资源</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 角色添加弹出框 -->
    <Addaccount :getAccount="getAccount" ref="addaccount"></Addaccount>
    <!-- 修改角色弹出框 -->
    <Reviserole ref="reviserole" :getAccount="getAccount"></Reviserole>
    <!-- 分配资源弹出框 -->
    <AllocateSource ref="AllocateSource" :getAccount="getAccount"></AllocateSource>
  </div>
</template>

<script>
//引入local
import local from "@/utils/local.js";
import { accList_api, del_role } from "../../../apis/acc";
import Addaccount from "./Addaccount.vue";
import Reviserole from "./Reviserole.vue";
import AllocateSource from './AllocateSource.vue'
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: { Addaccount, Reviserole,AllocateSource },
  data() {
    return {
       role_account_role_add:getRoleKey('role_account_role_add'),
       role_account_role_edit:getRoleKey('role_account_role_edit'),
       role_account_role_del:getRoleKey('role_account_role_del'),
       role_account_role_del:getRoleKey('role_account_role_del'),
       role_account_role_allot:getRoleKey('role_account_role_allot'),
      // 角色查询新增
      formInline: {
        user: "",
        region: "",
      },
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      //   角色表格
      tableData: [],
    };
  },
  created() {
    this.getAccount();
  },
  methods: {
    //角色查询
    onSubmit() {
      this.getAccount(this.formInline.user,this.formInline.region);
    },

    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.getAccount();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getAccount();
    },

    //分页获取用户列表
    async getAccount(roleName,roleKey) {
      let { code, data } = await accList_api({
        page: this.currentPage,
        rows: this.count,
        roleName:roleName,
        roleKey:roleKey
        // token: local.get("tk"),
      });
      if (code == 0) {
      //  console.log(data);
        this.tableData = data.records;
        this.total = data.total;

      }
    },
    //新增角色
    add() {
      this.$refs.addaccount.open();
    },
    //删除角色
    async del(row) {
      this.$confirm("此操作将永久删除该账号数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dels(row); // 调用删除功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除角色功能函数
    async dels(row){
      let { code, msg } = await del_role({
        ids: row.id,
      });
      if(code==0){
         this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getAccount();
      }
    },
    //修改角色
    revise(row) {
      this.$refs.reviserole.open(row)
    },
    // 分配资源
    AllocateSource(row){
      this.$refs.AllocateSource.open(row)
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner,
/deep/.el-range-input {
  background: #F6F8FB;
}
/deep/.has-gutter {
  background: #F6F8FB !important;
}

.roleManagement {
  /deep/.demo-form-inline {
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin-bottom: 0;
      margin-left: 15px;
      /deep/.el-table__header-wrapper {
        background: #ebf1fa;
      }
    }
  }
  .roleTable {
    width: 100%;
    // height: 550px;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-pagination {
      align-self: flex-end;
      padding: 10px;
      box-sizing: border-box;
    }
  }
}
</style>