<template>
  <div ref="revisegroup">
    <el-dialog
      title="群组修改"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="职位类别" :label-width="formLabelWidth">
          <el-select v-model="form.channel" placeholder="" size="small">
            <el-option
              v-for="item in check"
              :key="item.num"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="群组名称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="标签" :label-width="formLabelWidth">
          <el-select v-model="form.labelStatus" placeholder="" size="small">
            <el-option
              label="一般酒店"
              value="一般酒店"
              :disabled="
                form.channel == '售前' ||
                form.channel == '售后' ||
                form.channel == '二次确认'
              "
            ></el-option>
            <el-option
              label="核心酒店"
              value="核心酒店"
              :disabled="
                form.channel == '售前' ||
                form.channel == '售后' ||
                form.channel == '二次确认'
              "
            ></el-option>
            <el-option
              label="其他"
              value="其他"
              :disabled="form.channel == '产品' || form.channel == '运营'"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select v-model="form.status" placeholder="" size="small">
            <el-option label="正常" value="正常"></el-option>
            <el-option label="不正常" value="不正常"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.description"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.sort"
            placeholder="请输入阿拉伯数字"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div style="float: right">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getGroup: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        type: "", // 职位类别
        name: "", // 组名
        channel: "",// 类别
        status: "", // 状态 （禁用/正常）
        labelStatus: "", // 标签
        description: "", // 描述信息
        sort:''
      },
      check: [
        { name: "产品", num: 0 },
        { name: "运营", num: 1 },
        { name: "售前", num: 2 },
        { name: "售后", num: 3 },
        { name: "二次确认", num: 4 },
      ],
      formLabelWidth: "80px",
      id: "",
    };
  },
  methods: {
    open(row) {
      this.getGroup();
      this.dialogFormVisible = true;
      this.id = row.id;
   //   console.log(row, "需要修改的当前数据");
      this.form.name = row.groupName;
      this.form.sort = row.sort;
      this.form.description = row.description;
      this.form.channel =
        row.positionType == "0"
          ? "产品"
          : row.positionType == "1"
          ? "运营"
          : row.positionType == "2"
          ? "售前"
          : row.positionType == "3"
          ? "售后"
          : row.positionType == "4"
          ? "二次确认"
          : "";
      this.form.status =
        row.status == "1" ? "正常" : row.status == "2" ? "禁用" : "";
      this.form.labelStatus =
        row.typeCore == "0"
          ? "一般酒店"
          : row.typeCore == "1"
          ? "核心酒店"
          : row.typeCore == "2"
          ? "其他"
          : "";
    },
    //确认保存
    onSubmit() {
      this.reviseGroup();
      this.dialogFormVisible = false;
      // if (this.getGroup()) {
      //   this.getGroup();
      // }
      // this.form = {};
    },
    close() {
      this.dialogFormVisible = false;
    },
    //修改群组
    reviseGroup() {
      this.$axios({
        method: "post",
        url: "/otaHotel/horseGroup/creat",
        headers: {
          "Content-Type": "application/json",
        },
        // dataType: "json",
        data: {
          id: this.id,
          positionType:
            this.form.channel == "产品"
              ? "0"
              : this.form.channel == "运营"
              ? "1"
              : this.form.channel == "售前"
              ? "2"
              : this.form.channel == "售后"
              ? "3"
              : this.form.channel == "二次确认"
              ? "4"
              : "",
          groupName: this.form.name,
          status: this.form.status == "正常" ? "1" : "0",
          description: this.form.description,
          sort: this.form.sort,
          typeCore:
            this.form.labelStatus == "一般酒店"
              ? "0"
              : this.form.labelStatus == "核心酒店"
              ? "1"
              : this.form.labelStatus == "其他"
              ? "2"
              : "",
        },
      })
        .then((res) => {
          if (res.data.data == "修改成功") {
            this.$message({
              message: "恭喜您已成功修改数据!",
              type: "success",
            });
            this.getGroup(); // 重绘
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input--small {
  width: 292.6px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 80%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>