<template>
  <div ref="addgroup">
    <el-dialog
      title="群组添加"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="职位类别" :label-width="formLabelWidth">
          <el-select
            v-model="form.channel"
            placeholder="请选择职位类别"
            size="small"
          >
            <el-option
              v-for="item in check"
              :key="item.num"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="群组名称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入群组名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="标签" :label-width="formLabelWidth">
          <el-select
            v-model="form.labelStatus"
            placeholder="请选择群组标签"
            size="small"
          >
            <el-option
              label="一般酒店"
              value="一般酒店"
              :disabled="
                form.channel == '售前' ||
                form.channel == '售后' ||
                form.channel == '二次确认'
              "
            ></el-option>
            <el-option
              label="核心酒店"
              value="核心酒店"
              :disabled="
                form.channel == '售前' ||
                form.channel == '售后' ||
                form.channel == '二次确认'
              "
            ></el-option>
            <el-option
              label="其他"
              value="其他"
              :disabled="form.channel == '产品' || form.channel == '运营'"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select
            v-model="form.status"
            placeholder="请选择群组状态"
            size="small"
          >
            <el-option label="正常" value="正常"></el-option>
            <el-option label="禁用" value="禁用"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.description"
            autocomplete="off"
            placeholder="请输入群组描述信息"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getGroup: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        type: "", // 职位类别
        name: "", // 组名
        status: "", // 状态 （禁用/正常）
        labelStatus: "", // 标签
        description: "", // 描述信息
      },
      check: [
        { name: "产品", num: 0 },
        { name: "运营", num: 1 },
        { name: "售前", num: 2 },
        { name: "售后", num: 3 },
        { name: "二次确认", num: 4 },
      ],
      formLabelWidth: "80px",
    };
  },
  methods: {
    open() {
      this.dialogFormVisible = true;
    },
    //确认保存
    onSubmit() {
      this.addGroup();
      this.dialogFormVisible = false;
      if (this.getGroup()) {
        this.getGroup();
      }
      this.form = {};
    },
    close() {
      this.dialogFormVisible = false;
    },
    //新增群组
    addGroup() {
      this.$axios({
        method: "post",
        url: "/otaHotel/horseGroup/creat",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          positionType:
            this.form.channel == "产品"
              ? "0"
              : this.form.channel == "运营"
              ? "1"
              : this.form.channel == "售前"
              ? "2"
              : this.form.channel == "售后"
              ? "3"
              : this.form.channel == "二次确认"
              ? "4"
              : "",
          groupName: this.form.name,
          status: this.form.status == "正常" ? "1" : "0",
          description: this.form.description,
          typeCore:
            this.form.labelStatus == "一般酒店"
              ? "0"
              : this.form.labelStatus == "核心酒店"
              ? "1"
              : this.form.labelStatus == "其他"
              ? "2"
              : "",
        },
      })
        .then((res) => {
        //  console.log(res, "添加群组");
          if (res.data.data == "新增成功") {
            this.$message({
              message: "恭喜您已成功添加群组!",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input--small {
  width: 292.6px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 80%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>