<template>
  <div ref="addvest">
    <el-dialog
      title="分配马甲"
      :visible.sync="dialogFormVisible"
      width="40%"
      top="10rem"
      border-radius="30px"
      :close-on-click-modal="false"
    >
      <div class="info">
        <p class="name" style="margin: 0px 2px 0px 2px">
          组名: {{ groupName }}
        </p>
      </div>
      <el-form :model="form">
        <el-form-item prop="vest" label="马甲选择：">
          <!-- <el-checkbox-group v-model="form.vest"> -->
            <el-checkbox
              :checked="item.preSelected"
              @change="changeStatus(item)"
              v-for="item in checkList"
              :label="item.horseName"
              :key="item.id"
            ></el-checkbox>
          <!-- </el-checkbox-group> -->
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getNotVestlist_apiak } from "../../../apis/acc";
export default {
  props: {
    getGroup: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        vest: [],
      },
      formLabelWidth: "100px",
      checkList: [], // 多选
      newCheck: [], // 新的多选
      groupName: "", // 组名
      groupId: "", // 组id(获取分组马甲列表)
    };
  },
  created() {},
  methods: {
    open(row) {
     // console.log(row, "点击对应群组获取到的数据-群组分配马甲");
      this.groupId = row.id;
      this.groupName = row.groupName;
      this.dialogFormVisible = true;
      // 有groupId的时候发起请求
      this.getVestList();
    },
    async getVestList() {
      this.checkList=[]
      let { code, data } = await getNotVestlist_apiak({
        groupId: this.groupId,
      });
      if (code == 0) {
        this.checkList = data;
      //  console.log(this.checkList, "分组列表");
      }
    },
    //确认保存
    onSubmit() {
      this.dialogFormVisible = false;
      if (this.getGroup()) {
        this.getGroup();
      }
      this.$axios({
        method: "post",
        url: "/otaHotel/horseGroupMap/setPlatformIdMap",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          getHorseMapVo: {
            ctrip: this.checkList,
            filPig: [],
            goWhere: [],
            meiTuan: [],
            sameCity: [],
          },
          horseGroupId: this.groupId,
        },
      })
        .then((res) => {
        //  console.log(res, "分配马甲成功的响应数据");
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "分配马甲成功!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
     // console.log(this.checkList, "改变后携程数组数据");
    },
    // 多选
    changeStatus(item) {
      item.preSelected = !item.preSelected;
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  width: 90%;
  height: 38px;
  display: flex;
  align-items: center;
  background: #f6f8fb;
  margin-bottom: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding-left: 15px;
  box-sizing: border-box;
  margin-left: 25px;
  .name {
    font-weight: bold;
  }
}
/deep/.el-dialog {
  border-radius: 10px;
}
/deep/.el-select > .el-input {
  width: 240px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>