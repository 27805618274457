<template>
  <div ref="addperson">
    <el-dialog
      title="账号添加"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="账户名" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.user"
            autocomplete="off"
            placeholder="请输入您的账号名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input
            size="small"
            show-password
            v-model="form.pwd"
            autocomplete="off"
            minlength="6"
            placeholder="请输入您的密码(长度至少为6位)"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.nickname"
            autocomplete="off"
            placeholder="请输入您的昵称信息"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" :label-width="formLabelWidth">
          <el-select
            v-model="form.sex"
            placeholder="请选择您的性别"
            size="small"
          >
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.num"
            autocomplete="off"
            maxlength="11"
            placeholder="请输入您的手机号码"
            minlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业微信名称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.wechat"
            autocomplete="off"
            placeholder="请输入您的企业微信群账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select
            v-model="form.status"
            placeholder="请设置您的账号状态"
            size="small"
          >
            <el-option label="锁定" value="锁定"></el-option>
            <el-option label="未锁定" value="未锁定"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.description"
            autocomplete="off"
            placeholder="请输入账号描述信息"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { creatPerson } from "../../../apis/acc";
export default {
  props: {
    getPerson: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        user: "",
        pwd: "",
        nickname: "",
        sex: "",
        num: "",
        status: "",
        description: "",
        wechat: "",
      },
      formLabelWidth: "120px",
    };
  },
  methods: {
    open() {
      this.dialogFormVisible = true;
    },
    //确认保存
    onSubmit() {
      this.addPerson();
      this.dialogFormVisible = false;
      if (this.getPerson()) {
        this.getPerson();
      }
      this.form = {};
    },
    close() {
      this.dialogFormVisible = false;
    },
    //添加人员
    async addPerson() {
      // this.$axios({
      //   method: "post",
      //   url: "/otaHotel/manager/creat",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   data: {
      //     userName: this.form.user,
      //     passWord: this.form.pwd,
      //     nickName: this.form.nickname,
      //     sex: this.form.sex == "女" ? 0 : this.form.sex == "男" ? 1 : "",
      //     telephone: this.form.num,
      //     locked: this.form.status == "锁定" ? "1" : "0",
      //     description: this.form.description,
      //     wchatName:this.form.wechat
      //   },
      // })
      //   .then((res) => {
      //   //  console.log(res, "添加人员的响应数据");
      //     if (res.data.code == 0) {
      //       this.$message({
      //         message: "恭喜您已成功添加管理员!",
      //         type: "success",
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      let { code, data } = await creatPerson({
        userName: this.form.user,
        passWord: this.form.pwd,
        nickName: this.form.nickname,
        sex: this.form.sex == "女" ? 0 : this.form.sex == "男" ? 1 : "",
        telephone: this.form.num,
        locked: this.form.status == "锁定" ? "1" : "0",
        description: this.form.description,
        wchatName: this.form.wechat,
      });
      if (code == 0) {
        this.$message({
          message: "恭喜您已成功添加人员!",
          type: "success",
        });
         this.$$parent.getPerson();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input--small {
  width: 240px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 85%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  width: 240px;
  background-color: #f1f1f1;
}
</style>