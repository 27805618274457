<template>
  <div ref="reviseperson">
    <el-dialog
      title="账号修改"
      :visible.sync="dialogFormVisible"
      width="37%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="账户名" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.user"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input
            size="small"
            show-password
            v-model="form.pwd"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.nickname"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" :label-width="formLabelWidth">
          <el-select v-model="form.sex" placeholder="" size="small">
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth">
          <el-input
            size="small"
            maxlength="11"
            minlength="11"
            v-model="form.num"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select v-model="form.status" placeholder="" size="small">
            <el-option label="锁定" value="锁定"></el-option>
            <el-option label="未锁定" value="未锁定"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业微信名称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.wechat"
            autocomplete="off"
            placeholder="请输入您的企业微信群账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.description"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getPerson: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        user: "", // 账户名
        pwd: "", // mima
        nickname: "", // 昵称
        sex: "", // 性别
        num: "", // 电话
        status: "", // 状态
        description: "", // 描述
        wechat:'', // 微信
      },
      formLabelWidth: "130px",
      id: "", // 修改数据所需id
    };
  },
  methods: {
    open(row) {
    //  console.log(row, "获取到当前需要修改的整条数据");
      this.dialogFormVisible = true;
      this.id = row.id; // id赋值
      this.form.user = row.userName;
      this.form.wechat=row.wchatName;
      this.form.pwd = row.passWord;
      this.form.nickname = row.nickName;
      this.form.sex = row.sex == "1" ? "男" : row.sex == "0" ? "女" : "";
      this.form.num = row.telephone; // 电话
      this.form.status =
        row.locked == "1" ? "锁定" : row.locked == "0" ? "未锁定" : "";
      this.form.description = row.description;
    },
    //确认保存
    onSubmit() {
      this.revisePerson();
      this.dialogFormVisible = false;
      this.getPerson();
    },
    close() {
      this.dialogFormVisible = false;
    },
    //修改人员
    revisePerson() {
      this.$axios({
        method: "post",
        url: "/otaHotel/manager/creat",
        headers: {
          "Content-Type": "application/json",
        },
        // dataType: "json",
        data: {
          userName: this.form.user,
          passWord: this.form.pwd,
          nickName: this.form.nickname,
          sex: this.form.sex == "女" ? "0" : "1",
          telephone: this.form.num,
          locked: this.form.status == "锁定" ? "1" : "0",
          description: this.form.description,
          wchatName:this.form.wechat,
          id: this.id,
        },
      })
        .then((res) => {
        //  console.log(res, "修改人员数据的响应数据");
          if (res.data.code == 0) {
            this.$message({
              message: "恭喜您已成功修改账号数据",
              type: "success",
            });
            this.getPerson();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input--small {
  width: 292.6px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 100%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>