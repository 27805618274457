<template>
  <div class="account">
    <!-- 管理分类 -->
    <div class="choose">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        active-text-color="#256DEF"
      >
        <el-menu-item v-if="role_account_role" index="1">角色管理</el-menu-item>
        <el-menu-item v-if="role_account_person" index="2">人员管理</el-menu-item>
        <el-menu-item v-if="role_account_group" index="3">群组管理</el-menu-item>
        <el-menu-item v-if="role_account_vest" index="4">马甲管理</el-menu-item>
      </el-menu>
    </div>
    <!-- 对应页面 -->
    <RoleManagement v-if="index == '1'"></RoleManagement>
    <PersonnelManagement v-if="index == '2'"></PersonnelManagement>
    <GroupManagement v-if="index == '3'"></GroupManagement>
    <VestManagement v-if="index == '4'"></VestManagement>
  </div>
</template>

<script>
import RoleManagement from "../../components/dialog/account/RoleManagement.vue";
import PersonnelManagement from "../../components/dialog/account/PersonnelManagement.vue";
import GroupManagement from "../../components/dialog/account/GroupManagement.vue";
import VestManagement from "../../components/dialog/account/VestManagement.vue";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: {
    RoleManagement,
    PersonnelManagement,
    GroupManagement,
    VestManagement,
  },
  data() {
    return {
       role_account_role:getRoleKey('role_account_role'),
       role_account_person:getRoleKey('role_account_person'),
       role_account_group:getRoleKey('role_account_group'),
       role_account_vest:getRoleKey('role_account_vest'),
       role_account_add:getRoleKey('role_account_add'),
      activeIndex: "1", //管理分类
      index: "1", //管理分类与组件对应展示的方式
    };
  },
  methods: {
    //管理分类
    handleSelect(key, keyPath) {
    //  console.log(key, keyPath);
      this.index = key;
    },
  },
};
</script>

<style lang="less" scoped>
.account {
  .choose {
    width: 100%;
    height: 60px;
    background: #fff;
    margin-bottom: 20px;
    .el-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    }
  }
}
</style>