<template>
  <div class="groupManagement">
    <!-- 群组查询/新增 -->
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="群组">
        <el-input
          size="small"
          clearable
          v-model="formInline.user"
          placeholder="请输入要查询的群组名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" @click="onSubmit" type="primary">查询</el-button>
        <el-button v-if="role_account_group_add" size="mini" @click="add" type="success">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 群组表格 -->
    <div class="roleTable">
      <div class="head">
        <!-- 按钮选项 -->
        <div class="btn">
          <el-button
            size="mini"
            :class="btnCur == index ? 'active' : ''"
            v-for="(item, index) in btn"
            :key="index"
            @click="btnFn(index)"
            >{{ item.name }}</el-button
          >
        </div>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="count"
          :page-sizes="[15, 30, 45, 60]"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
        
          prop="positionType"
          label="职位类别"
          align="center"
          width="130px"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.positionType == "0"
                  ? "产品"
                  : scope.row.positionType == "1"
                  ? "运营"
                  : scope.row.positionType == "2"
                  ? "售前"
                  : scope.row.positionType == "3"
                  ? "售后"
                  : "二次确认"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="groupName" label="群组名称" align="center">
        </el-table-column>
        <el-table-column label="状态" align="center" width="60">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium" v-if="scope.row.status == 1" type="success"
                >正常</el-tag
              >
              <el-tag size="medium" v-if="scope.row.status == 0" type="danger"
                >禁用</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="已分配马甲" >
          <template slot-scope="scope">
         
              <el-tag size="medium" style="margin-left:5px;margin-bottom:5px;" v-for="(item,index) in scope.row.horseVestNameList" :key="index" 
                >{{item}}</el-tag
              >
            
          
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
         
          width="260px"
        >
          <template slot-scope="scope">
            <el-button v-if="role_account_group_edit" type="primary" @click="revise(scope.row)" size="mini"
              >修改</el-button
            >
            <el-button v-if="role_account_group_del" type="danger" @click="del(scope.row)" size="mini"
              >删除</el-button
            >
            <el-button
              v-if="scope.row.positionType == '0'"
              type="warning"
              size="mini"
              @click="distribution(scope.row)"
              >分配马甲</el-button
            >
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="prop">
             <el-table :data="prop.row.managerList">
               <el-table-column prop="nickName" label="姓名"></el-table-column>
               <el-table-column prop="roleName" label="角色"></el-table-column>
               <el-table-column prop="telephone" label="电话"></el-table-column>
             </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增群组弹窗 -->
    <Addgroup ref="addgroup" :getGroup="getGroup"></Addgroup>
    <!-- 修改群组弹窗 -->
    <Revisegroup ref="revisegroup" :getGroup="getGroup"></Revisegroup>
    <!-- 分配群组弹窗 -->
    <Distribution ref="distribution" :getGroup="getGroup"></Distribution>
  </div>
</template>

<script>
import Addgroup from "./Addgroup.vue";
import Revisegroup from "./Revisegroup.vue";
import Distribution from "./DistributionDialog.vue";
import { group_list, delGroup_api } from "../../../apis/acc";
import {getRoleKey} from "@/utils/rolekey"
export default {
  components: {
    Addgroup,
    Revisegroup,
    Distribution,
  },
  data() {
    return {
       role_account_group_add:getRoleKey('role_account_group_add'),
       role_account_group_edit:getRoleKey('role_account_group_edit'),
       role_account_group_del:getRoleKey('role_account_group_del'),
      btnCur: "0", // 选项卡按钮
      formInline: {
        user: "", // 角色查询新增
      },
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      tableData: [], //   角色表格
      oldList: [],
      btn: [
        { name: "全部" }, //选项按钮
        { name: "产品" },
        { name: "运营" },
        { name: "售前" },
        { name: "售后" },
        { name: "二次确认" },
      ],
    };
  },
  created() {
    this.getGroup();
  },
  methods: {
    // 选项卡点击事件
    btnFn(index) {
    //  console.log(index);
      this.btnCur = index;
      if (index == "0") {
        this.getGroup();
      } else if (index == "1") {
        this.getGroup("", 0);
      } else if (index == "2") {
        this.getGroup("", 1);
      } else if (index == "3") {
        this.getGroup("", 2);
      } else if (index == "4") {
        this.getGroup("", 3);
      } else if (index == "5") {
        this.getGroup("", 4);
      }
    },
    //分页
    handleSizeChange(val) {
      this.count = val;
      this.getGroup();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getGroup();
    },
    //分页获取群组列表
    async getGroup(groupName, positionType) {
      let { code, data } = await group_list({
        page: this.currentPage,
        rows: this.count,
        groupName: groupName,
        positionType: positionType,
      });
      if (code == 0) {
      //  console.log(data.records, "查询到的所有群组");
        this.tableData = data.records;
        this.oldList = data.records;
        this.total = data.total;
      }
    },
    //查询群组
    onSubmit() {
      this.getGroup(this.formInline.user, "");
    },
    // 新增群组
    add() {
      this.$refs.addgroup.open();
    },
    //删除群组 提示框
    del(row) {
      this.$confirm("此操作将永久删除该组数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dels(row); // 调用删除功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除功能函数
    async dels(row) {
      let { code, data } = await delGroup_api({
        ids: row.id,
      });
      if (code == 0) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getGroup();
      }
    },
    //修改群组
    revise(row) {
      this.$refs.revisegroup.open(row);
    },
    // 分配马甲
    distribution(row) {
      this.$refs.distribution.open(row);
    },
    // 群组类别查询的失焦处理函数
    blurType(item) {
      item == "";
      this.getGroup();
    },
    // 群组查询的失焦处理函数
    blurGroup(item) {
      item == "";
      this.getGroup();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner,
/deep/.el-range-input {
  background: #f6f8fb;
}
/deep/.has-gutter {
  background: #f6f8fb !important;
}
// /deep/.el-table .cell {
//   display: flex !important;
//   align-items: center !important;
// }
.groupManagement {
  /deep/.demo-form-inline {
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  .roleTable {
    width: 100%;
    // height: 550px;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .head {
      display: flex;
      justify-content: space-between;
      .btn {
        padding: 10px;
        box-sizing: border-box;
        button {
          border: 1px solid #256def;
          color: #256def;
        }
        .el-button.active {
          background: #256def;
          color: #fff;
        }
      }
      .el-pagination {
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>