<template>
  <div ref="addvest">
    <el-dialog
      title="分配资源"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
      @closed="cleanDataFn"
    >
      <div class="info">
        <p class="name" style="margin: 0px 2px 0px 2px">
          为{{ roleName }}分配资源
        </p>
      </div>
      <div style="text-align: center">
        <el-tree
          style="width: 200px; margin-left: 50px"
          ref="Ztree"
          :check-strictly="checkStrictly"
          :current-node-key="currentNode"
          :data="treeData"
          :props="defaultProps"
          default-expand-all
          expand-on-click-node
          highlight-current
          node-key="id"
          show-checkbox
          @check-change="changeTree"
        />
      </div>
      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPowerTree_api, setPowerTree_api } from "../../../apis/acc";
export default {
  props: {
    getAccount: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        vest: [],
      },
      formLabelWidth: "100px",
      checkList: [], // 多选
      newCheck: [], // 新的多选
      groupName: "资源", // 组名
      groupId: "", // 组id(获取分组马甲列表)

      roleName: "",
      roleId: "",
      currentNodeKey: "",
      checkStrictly: true, //是否关联父级
      currentNode: [], //选中的节点
      //tree的默认属性
      defaultProps: {
        label: "name",
      },
      source: [],
      treeData: [],
    };
  },
  created() {
    // 获取分组列表
    // this.getVestList();
  },
  methods: {
    open(row) {
      //   console.log(row, "点击对应角色获取到的数据-角色分配资源");
      this.roleId = row.id;
      this.roleName = row.name;
      this.getPowerTree();
      this.dialogFormVisible = true;
    },
    async getPowerTree() {
      let { code, data } = await getPowerTree_api({
        RoleId: this.roleId,
      });
      if (code == 0) {
        //  this.createTree(data);
        this.currentNode = [];

        data.forEach((item) => {
          if (item.isSelected == true) {
            this.currentNode.push(item.id);
          }
          if (item.children) {
            item.children.forEach((ctem) => {
              if (ctem.isSelected == true) {
                this.currentNode.push(ctem.id);
              }
              if (ctem.children) {
                ctem.children.forEach((etem) => {
                  if (etem.isSelected == true) {
                    this.currentNode.push(etem.id);
                  }
                });
              }
            });
          }
        });
        this.treeData = data;
        this.$refs.Ztree.setCheckedKeys(this.currentNode);
        //   console.log(this.currentNode);
      }
    },
    createTree(data) {
      this.source = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].parentId == -1) {
          this.source.push(data[i]);
          this.recursion(data[i], data);
        }
      }
    },
    recursion(node, data) {
      node.children = [];
      for (let i = 0; i < data.length; i++) {
        if (node.id == data[i].parentId && node.id != data[i].id) {
          node.children.push(data[i]);
          this.recursion(data[i], data);
        }
      }
    },
    changeTree(data, row, col) {
      // console.log(data);
      // console.log(row);
      // console.log(col);
      data.isSelected = row;
    },
    //确认保存
    async handEditData() {
      let { code, data } = await setPowerTree_api({
        powerList: this.treeData,
        roleId: this.roleId,
      });
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
      }
    },
    // 多选
    changeStatus(item) {
      item.selected = !item.selected;
      // console.log(this.checkList,'改变后')
    },
    cleanFn() {
      this.dialogFormVisible = false;
    },
    cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  width: 90%;
  height: 38px;
  display: flex;
  align-items: center;
  background: #f6f8fb;
  margin-bottom: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding-left: 15px;
  box-sizing: border-box;
  margin-left: 25px;
  .name {
    font-weight: bold;
  }
}
/deep/.el-dialog {
  border-radius: 10px;
}
/deep/.el-select > .el-input {
  width: 240px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>