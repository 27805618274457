<template>
  <div ref="addvest">
    <el-dialog
      title="关联角色"
      :visible.sync="dialogFormVisible"
      width="36%"
      top="10rem"
      border-radius="30px"
      :close-on-click-modal="false"
    >
      <div class="info">
        <p class="name" style="margin: 0px 2px 0px 2px">
          为<span class="info-text">{{ accountName }}</span
          >关联角色
        </p>
      </div>
      <el-form :model="form">
        <el-form-item prop="vest" label="角色选择">
          <el-select v-model="form.channel" placeholder="请选择关联的角色">
            <el-option
              v-for="item in checkList"
              :key="item.num"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getRolesList_apiak } from "../../../apis/acc";
export default {
  props: {
    getPerson: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        channel: "", // 类别
      },
      formLabelWidth: "100px",
      checkList: [], // 多选
      newCheck: [], // 新的多选
      accountName: "", // 组名
      id: "", // 账号id
    };
  },
  created() {},
  methods: {
    open(row) {
    //  console.log(row, "点击对应人员获取到的数据-账号分配角色");
      this.dialogFormVisible = true;
      this.accountName = row.userName;
      // 有groupId的时候发起请求
      this.id = row.id;
      this.getVestList(row.id);
    },
    async getVestList(id) {
      let { code, data } = await getRolesList_apiak({
        page: 1,
        rows: 100,
        managerId: id,
      });
      if (code == 0) {
        this.checkList = data.records;
     //   console.log(data, "获取到的所有角色分页");
      }
    },
    //确认保存
    onSubmit() {
     // console.log(this.form.channel, "值");
      if (this.form.channel) {
        this.$axios({
          method: "post",
          url: "/otaHotel/hanagerRoleMapRoleMap/creat",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            managerId: this.id,
            roleId: this.form.channel,
          },
        })
          .then((res) => {
            // if (res.data.data == "新增成功") {
            //   this.$message({
            //     type: "success",
            //     message: "恭喜您,关联角色成功!",
            //   });
            //   this.dialogFormVisible = false;
            //   if (this.getPerson()) {
            //     this.getPerson();
            //   }
            //   this.form = {};
            // }

            if(res.data.code==0){
              this.$message.success(res.data.data?res.data.data:"关联成功")
               this.dialogFormVisible = false;
               this.$parent.onSubmit()
            }



          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$message({
          type: "info",
          message: "请选择角色类型!",
        });
      }
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  width: 80%;
  height: 38px;
  display: flex;
  align-items: center;
  background: #f6f8fb;
  margin-bottom: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding-left: 15px;
  box-sizing: border-box;
  margin-left: 50px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
/deep/.el-select > .el-input {
  width: 280px;
}
/deep/.radio {
  width: 170px;
  height: 40px;
}
.el-form {
  width: 80%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
.info-text {
  margin: 0px 2px;
  font-weight: bold;
  color: #333333;
}
/deep/.el-input--small {
  width: 292.6px;
}
</style>