<template>
  <div class="personnelManagement">
    <!-- 人员查询/新增 -->
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="昵称">
        <el-input
          size="small"
          clearable
          v-model="formInline.user"
          placeholder="请输入要查询的昵称"
        ></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input
          size="small"
          clearable
          v-model="formInline.num"
          placeholder="请输入要查询的电话"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          v-if="role_account_person_add"
          size="mini"
          type="primary"
          @click="onSubmit"
          >查询</el-button
        >
        <el-button size="mini" type="success" @click="add">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 人员表格 -->
    <div class="roleTable">
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="count"
        :page-sizes="[10, 15, 30, 45, 60]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          width="120"
          fixed
          prop="userName"
          label="账号名"
          align="center"
        >
        </el-table-column>
        <el-table-column
          width="180"
          prop="nickName"
          label="昵称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          width="100"
          prop="gropIdName"
          label="组别"
          align="center"
        >
        </el-table-column>
        <el-table-column width="80" prop="roleName" label="角色" align="center">
        </el-table-column>
        <el-table-column prop="sex" width="80" label="性别" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.sex == "0" ? "女" : "男" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="telephone"
          label="电话"
          width="120px"
          align="center"
        >
        </el-table-column>
        <el-table-column label="酒店上限" align="center">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <span v-if="scope.row.hotelNum != 0">{{
                scope.row.hotelNum
              }}</span>
              <span v-else>无限制</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="上班状态" align="center">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag
                size="medium"
                v-if="scope.row.workStatus == 1"
                type="success"
                >上班中</el-tag
              >
              <el-tag
                size="medium"
                v-if="scope.row.workStatus == 0"
                type="danger"
                >休息中</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="帐号状态" align="center">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium" v-if="scope.row.locked == 1" type="danger"
                >锁定</el-tag
              >
              <el-tag size="medium" v-if="scope.row.locked == 0" type="success"
                >未锁定</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          width="180"
          label="创建时间"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="createTime"
          width="180"
          label="企业微信群名称"
          align="center"
        >
        </el-table-column> -->
        <el-table-column
          prop="updateTime"
          width="180"
          label="更新时间"
          align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="role_account_person_edit"
              class="special-button"
              type="primary"
              @click="revise(scope.row)"
              size="mini"
              >修改</el-button
            >
            <el-button
              v-if="role_account_person_del"
              class="special-button"
              type="danger"
              @click="del(scope.row)"
              size="mini"
              >删除</el-button
            >
            <el-button
              class="special-button"
              type="primary"
              size="mini"
              @click="associatedRole(scope.row)"
              >关联角色</el-button
            >
            <!-- <el-button
              class="special-button"
              disabled
              type="primary"
              v-if="scope.row.roleKey"
              size="mini"
              >已关联角色</el-button
            > -->
            <el-button
              class="special-button"
              type="warning"
              size="mini"
              @click="associatesGroup(scope.row)"
              >关联群组</el-button
            >
            <el-button
              class="special-button"
              type="success"
              size="mini"
              @click="openSetHotelNum(scope.row)"
              >设置上限</el-button
            >
            <!-- <el-button  class="special-button" disabled type="warning" size="mini" v-if="scope.row.gropId">已关联群组</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 人员添加弹出框 -->
    <Addperson :getPerson="getPerson" ref="addperson"></Addperson>
    <!-- 修改人员弹出框 -->
    <Reviseperson ref="reviseperson" :getPerson="getPerson"></Reviseperson>
    <!-- 关联角色弹出框 -->
    <AssociatesRole
      ref="AssociatesRole"
      :getPerson="getPerson"
    ></AssociatesRole>
    <!-- 关联群组弹出框 -->
    <AssociatesGroup
      ref="AssociatesGroup"
      :getPerson="getPerson"
    ></AssociatesGroup>
    <Sethotelnumdia ref="sethotelnumdia"></Sethotelnumdia>
  </div>
</template>

<script>
import { person_list, delPerson_api } from "../../../apis/acc";
import Addperson from "./Addperson.vue";
import Reviseperson from "./Reviseperson.vue";
import AssociatesRole from "./AssociatedRole.vue";
import AssociatesGroup from "./AssociatesGroup.vue";
import Sethotelnumdia from "./Sethotelnumdia.vue";
import { getRoleKey } from "@/utils/rolekey";
export default {
  components: { Addperson, Reviseperson, AssociatesRole, AssociatesGroup,Sethotelnumdia },
  data() {
    return {
      role_account_person_add: getRoleKey("role_account_person_add"),
      role_account_person_edit: getRoleKey("role_account_person_edit"),
      role_account_person_del: getRoleKey("role_account_person_del"),
      // 角色查询新增
      formInline: {
        user: "",
        num: "",
      },
      currentPage: 1, //当前页
      count: 15, //当前页条数
      total: 0, //总数
      //   角色表格
      tableData: [],
    };
  },
  created() {
    this.getPerson();
  },
  methods: {
    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.count = val;
      this.currentPage = 1;
      this.getPerson();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getPerson();
    },
    //关键字查询人员
    onSubmit() {
      this.currentPage = 1;
      this.getPerson();
    },
    //分页获取人员列表
    async getPerson() {
      let { code, data } = await person_list({
        page: this.currentPage,
        rows: this.count,
        userName: this.formInline.user,
        tel: this.formInline.num,
      });
      if (code == 0) {
        this.tableData = data.records;
        this.total = data.total;
      }
    },
    //新增人员
    add() {
      this.$refs.addperson.open();
    },
    //删除用户
    async del(row) {
      this.$confirm("此操作将永久删除该组数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dels(row); // 调用删除功能函数
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除用户功能函数
    async dels(row) {
      let { code, msg } = await delPerson_api({
        ids: row.id,
      });
      if (code == 0) {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getPerson();
      }
    },
    //修改用户
    revise(row) {
      this.$refs.reviseperson.open(row);
    },
    // 关联角色
    associatedRole(row) {
      this.$refs.AssociatesRole.open(row);
    },
    // 关联群组
    associatesGroup(row) {
      if (row.roleKey) {
        this.$refs.AssociatesGroup.open(row);
      } else {
        this.$message({
          type: "info",
          message: "请您先关联角色!",
        });
      }
    },
    //打开酒店上限模态框
    openSetHotelNum(row) {
      this.$refs.sethotelnumdia.open(row);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner,
/deep/.el-range-input {
  background: #f6f8fb;
}
/deep/.has-gutter {
  background: #f6f8fb !important;
}
/deep/.special-button {
  padding: 4px 8px;
  box-sizing: border-box;
}
.personnelManagement {
  /deep/.demo-form-inline {
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-form-item {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  .roleTable {
    width: 100%;
    // height: 550px;
    margin-top: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
    .el-pagination {
      align-self: flex-end;
      padding: 10px;
      box-sizing: border-box;
    }
  }
}
</style>