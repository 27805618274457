<template>
  <div ref="addvest">
    <el-dialog
      title="关联群组"
      :visible.sync="dialogFormVisible"
      width="40%"
      top="10rem"
      border-radius="30px"
      :close-on-click-modal="false"
    >
      <div class="info">
        <p class="name" style="margin: 0px 2px 0px 2px">
          为<span class="info-text">{{ userName }}</span
          >分配群组
        </p>
      </div>
      <el-form :model="form">
        <el-form-item prop="vest" label="群组选择">
          <!-- <el-checkbox-group v-model="form.vest">
            <el-checkbox
              :checked="item.selected"
              @change="changeStatus(item)"
              v-for="(item, index) in checkList"
              :label="item.groupName"
              :key="index"
              :disabled="item.selectde ? true : false"
            ></el-checkbox>
          </el-checkbox-group> -->
          <el-radio-group v-model="groupId">
            <el-radio v-for="(item, index) in checkList" :key="index" :label="item.id"
              >{{item.groupName}}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getGroupList_apiak,
  setHorseGroupManagerId_apiak,
} from "../../../apis/acc";
export default {
  props: {
    getPerson: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        vest: [],
      },
      formLabelWidth: "100px",
      checkList: [], // 多选
      newCheck: [], // 新的多选
      userName: "", // 组名
      groupId: "", // 组id(获取分组马甲列表)
      groupId: "", // 组id
      managerId: "", // 用户id
    };
  },
  created() {},
  methods: {
    open(row) {
   //   console.log(row, "点击对应人员获取到的数据-账号分配群组");
      this.userName = row.userName;
      this.dialogFormVisible = true;
      this.managerId = row.id;
      this.getAllGroupList(row.roleKey,row.id);
    },
    // 获取所有分组信息的功能函数
    async getAllGroupList(key,id) {
      let { data } = await getGroupList_apiak({
        key: key, // 指定唯一key获取对应角色分组
        id:id,
      });
      if (data.length >= 0) {
        this.checkList = data;
        data.forEach(item => {
           if(item.selected==true){
             this.groupId = item.id;
           }
        });
      //  this.groupId = data[0].id;
      }
 //     console.log(data, "分组列表");
    },
    // 多选
    changeStatus(item) {
      item.selected = !item.selected;
   //   console.log(item, "选择");
    },
    //确认保存
    async onSubmit() {
      if (this.checkList.length > 0) {
        if (this.getPerson()) {
          this.getPerson();
        }
        let { code, data } = await setHorseGroupManagerId_apiak({
          groupId: this.groupId,
          managerId: this.managerId,
        });
        if (code == 0) {
          this.$message({
            type: "success",
            message: "关联群组设置成功",
          });
          this.dialogFormVisible = false;
        } else {
          this.$message({
            type: "error",
            message: "关联群组设置失败",
          });
          this.dialogFormVisible = false;
        }
      } else {
        this.$message({
          type: "info",
          message: "暂无可关联群组!",
        });
      }
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  width: 90%;
  height: 38px;
  display: flex;
  align-items: center;
  background: #f6f8fb;
  margin-bottom: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding-left: 15px;
  box-sizing: border-box;
  margin-left: 25px;
  .name {
    font-weight: normal;
  }
}
/deep/.el-dialog {
  border-radius: 10px;
}
/deep/.el-select > .el-input {
  width: 240px;
}
.el-form {
  width: 70%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
.info-text {
  margin: 0px 2px;
  color: #333333;
  font-weight: bold;
}
</style>